import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleMouton.scss';

function ModaleMouton({ isOpen, onClose, cardData, setCardData }) {
  const [mode, setMode] = useState('add');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [lien, setLien] = useState('');
  const [lienText, setLienText] = useState('');
  const [imagePosition, setImagePosition] = useState('50');
  const [selectedCard, setSelectedCard] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    resetForm();
  }, [mode]);

  const resetForm = () => {
    setTitle('');
    setText('');
    setImageUrl('');
    setLien('');
    setLienText('');
    setImagePosition('50');
    setSelectedCard(null);
    setFile(null);
  };

  const handleAddLink = () => {
    const linkMarkup = `[${lienText}](${lien})`;
    setText((prevText) => `${prevText} ${linkMarkup}`);
    setLien('');
    setLienText('');
  };

  const handleAdd = () => {
    if (!title || !text || !file) {
      alert('Veuillez remplir tous les champs obligatoires. (titre, texte et l’image)');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    formData.append('lien', lien);
    formData.append('lienText', lienText);
    formData.append('imagePosition', imagePosition);
    if (file) {
      formData.append('image', file);
    }

    fetch('http://localhost:5000/api/mouton/add', {
      method: 'POST',
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        setCardData(data.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error adding card:', error));
  };

  const handleEdit = () => {
    if (!selectedCard) return;
    if (!title || !text || (!file && !imageUrl)) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('originalTitle', selectedCard.title);
    formData.append('title', title);
    formData.append('text', text);
    formData.append('lien', lien);
    formData.append('lienText', lienText);
    formData.append('imagePosition', imagePosition || selectedCard.imagePosition);

    if (file) {
      formData.append('image', file);
    }

    fetch('http://localhost:5000/api/mouton/update', {
      method: 'PUT',
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        setCardData(data.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error updating card:', error));
  };

  const handleDelete = () => {
    if (!selectedCard) return;

    fetch('http://localhost:5000/api/mouton/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ title: selectedCard.title }),
    })
      .then(res => res.json())
      .then(data => {
        setCardData(data.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error deleting card:', error));
  };

  const handleSelectChange = (e) => {
    const selectedTitle = e.target.value;
    const selected = cardData.find(card => card.title === selectedTitle);
    setSelectedCard(selected);
    if (selected) {
      setTitle(selected.title);
      setText(selected.text);
      setImageUrl(selected.imageUrl);
      setLien(selected.lien);
      setLienText(selected.lienText);
      setImagePosition(selected.imagePosition);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'add' ? 'active' : ''} onClick={() => setMode('add')}>Ajouter</button>
            <button className={mode === 'edit' ? 'active' : ''} onClick={() => setMode('edit')}>Modifier</button>
            <button className={mode === 'delete' ? 'active' : ''} onClick={() => setMode('delete')}>Supprimer</button>
          </div>
          <div className="modale-body">
            {mode === 'add' && (
              <>
                <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                <textarea placeholder="Texte" value={text} onChange={(e) => setText(e.target.value)} required/>
                <input type="file" accept="image/*" onChange={handleImageUpload} required/>
                <input 
                  type="number" 
                  placeholder="Cadre de l'image (%)" 
                  value={imagePosition} 
                  onChange={(e) => setImagePosition(e.target.value)} 
                  min="0" 
                  max="100" 
                  required
                />
                <div className="link-inputs">
                  <input 
                    type="text" 
                    placeholder="Texte du lien" 
                    value={lienText} 
                    onChange={(e) => setLienText(e.target.value)} 
                  />
                  <input 
                    type="text" 
                    placeholder="URL du lien" 
                    value={lien} 
                    onChange={(e) => setLien(e.target.value)} 
                  />
                  <button type="button" onClick={handleAddLink}>Ajouter le lien au texte</button>
                </div>
                <div className="image-preview">
                  <div className="image-container">
                    <img src={imageUrl || '/placeholder-image.png'} alt="Preview" style={{ objectPosition: `center ${imagePosition}%` }} />
                  </div>
                </div>
                <button onClick={handleAdd}>Ajouter</button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <select value={selectedCard ? selectedCard.title : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner une carte</option>
                  {cardData.map((card, index) => (
                    <option key={index} value={card.title}>{card.title}</option>
                  ))}
                </select>
                {selectedCard && (
                  <>
                    <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                    <textarea placeholder="Texte" value={text} onChange={(e) => setText(e.target.value)} required/>
                    <div className="link-inputs">
                      <input 
                        type="text" 
                        placeholder="Texte du lien" 
                        value={lienText} 
                        onChange={(e) => setLienText(e.target.value)} 
                      />
                      <input 
                        type="text" 
                        placeholder="URL du lien" 
                        value={lien} 
                        onChange={(e) => setLien(e.target.value)} 
                      />
                      <button type="button" onClick={handleAddLink}>Ajouter le lien au texte</button>
                    </div>
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                    Position de l'image
                    <input 
                      type="number" 
                      placeholder="Cadre de l'image (%)" 
                      value={imagePosition} 
                      onChange={(e) => setImagePosition(e.target.value)} 
                      min="0" 
                      max="100" 
                      required
                    />
                    <div className="image-preview">
                      <div className="image-container">
                        <img src={imageUrl || '/placeholder-image.png'} alt="Preview" style={{ objectPosition: `center ${imagePosition}%` }} />
                      </div>
                    </div>
                    <button onClick={handleEdit}>Modifier</button>
                  </>
                )}
              </>
            )}
            {mode === 'delete' && (
              <>
                <select value={selectedCard ? selectedCard.title : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner une carte</option>
                  {cardData.map((card, index) => (
                    <option key={index} value={card.title}>{card.title}</option>
                  ))}
                </select>
                {selectedCard && <button onClick={handleDelete}>Supprimer</button>}
              </>
            )}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleMouton;
