import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFloppyDisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import './annonceAccueil.scss';

import staticAnnonces from '../../data/annonces.json';

function AnnonceAccueil() {
  const isLocalEnv = window.location.hostname === 'localhost';
  const [isEditing, setIsEditing] = useState(false);
  const [annonces, setAnnonces] = useState([]);
  const [editedMessage, setEditedMessage] = useState('');
  const [editedLinkText, setEditedLinkText] = useState('');
  const [editedLinkUrl, setEditedLinkUrl] = useState('');

  useEffect(() => {
    if (isLocalEnv) {
      fetch('http://localhost:5000/api/annonces')
        .then((res) => res.json())
        .then((data) => {
          setAnnonces(data);
          if (data.length > 0) {
            setEditedMessage(data[0].annonce);
            setEditedLinkText(data[0].textLien);
            setEditedLinkUrl(data[0].lien);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    } else {
      setAnnonces(staticAnnonces);
      if (staticAnnonces.length > 0) {
        setEditedMessage(staticAnnonces[0].annonce);
        setEditedLinkText(staticAnnonces[0].textLien);
        setEditedLinkUrl(staticAnnonces[0].lien);
      }
    }
  }, [isLocalEnv]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  
    const originalAnnonce = isLocalEnv ? annonces[0] : staticAnnonces[0];
    setEditedMessage(originalAnnonce.annonce);
    setEditedLinkText(originalAnnonce.textLien);
    setEditedLinkUrl(originalAnnonce.lien);
  };
  

  const handleSaveClick = () => {
    const updatedAnnonces = [
      {
        annonce: editedMessage,
        textLien: editedLinkText,
        lien: editedLinkUrl
      }
    ];

    if (isLocalEnv) {
      fetch('http://localhost:5000/api/annonces', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedAnnonces)
      })
        .then((res) => res.json())
        .then((data) => {
          setAnnonces(updatedAnnonces);
          setIsEditing(false);
          console.log('Data updated successfully:', data);
        })
        .catch((error) => console.error('Error updating data:', error));
    } else {
      console.log('Les modifications ne sont pas possibles en production.');
    }
  };

  return (
    <div className='annonce'>
      {isEditing ? (
        <div className='div__modifierAnnonce'>
          <div className='annonceText'>
            Message de l'annonce :
            <input
              type="text"
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
            />
          </div>
          <div className='lien__div'>
            Message du lien :
            <input
              type="text"
              value={editedLinkText}
              className='lienText'
              onChange={(e) => setEditedLinkText(e.target.value)}
            />
            Lien vers la page :
            <select
              value={editedLinkUrl}
              className='lienPage'
              onChange={(e) => setEditedLinkUrl(e.target.value)}
            >
              <option value="/houblon">Houblons bretons</option>
              <option value="/produit">Produits</option>
              <option value="/visite">Rendez-nous Visite</option>
              <option value="/mouton">La race Belle Île</option>
              <option value="/mentions">On parle de nous</option>
              <option value="/contact">Contact</option>
            </select>
          </div>
          <button className='saveAnnonce' onClick={handleSaveClick}>
            <FontAwesomeIcon icon={faFloppyDisk} />
          </button>
          <button onClick={handleCancelClick} className="cancelAnnonce">
              <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
      ) : (
        <div className='div__annonce'>
          <span>{annonces[0]?.annonce}</span>
          <Link to={annonces[0]?.lien}>{annonces[0]?.textLien}</Link>
          {isLocalEnv && (
            <button className='modifierAnnonce' onClick={handleEditClick}>
              <FontAwesomeIcon icon={faPen} />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default AnnonceAccueil;
