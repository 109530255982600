import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import contactInfo from '../../data/contactInfo.json';
import visiteData from '../../data/visite.json';
import './visite.scss';
import ModaleVisite from '../../components/modaleVisite/modaleVisite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Visite() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(visiteData);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const formatTelephoneForLink = (telephone) => {
    return telephone.replace(/[.\s]/g, '');
  };

  const replacePlaceholders = (text) => {
    return text
      .replace(/<telephone>/g, `<a href="tel:${formatTelephoneForLink(contactInfo.telephone)}">${contactInfo.telephone}</a>`)
      .replace(/<email>/g, `<a href="mailto:${contactInfo.email}">${contactInfo.email}</a>`);
  };

  const renderContent = (text) => {
    const contentWithPlaceholders = replacePlaceholders(text);
    const contentWithBreaks = contentWithPlaceholders.replace(/\n\s*\n/g, '<br><br>').replace(/\n/g, '<br>');
    return contentWithBreaks;
  };

  return (
    <div className="visite">
      <SectionTitle id="visite" />
      <div className='all__section'>
        {window.location.hostname === 'localhost' && (
          <button onClick={() => setIsModalOpen(true)} className='btn-modifier'>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
        <section className="section">
          <article className="text-box left-top">
            <h2>{data.title1}</h2>
            <div dangerouslySetInnerHTML={{ __html: renderContent(data.text1) }} />
            <Link to="/contact" className="btn-reserver">Réserver</Link>
          </article>
          <div className="photo-box summer right-top">
            <img src={data.photo1} alt="Visite guidée de la ferme" />
          </div>
        </section>
        <section className="section">
          <div className="photo-box hop left-bottom">
            <img src={data.photo2} alt="Ferme de la Houblonnière de Lezerzot" />
          </div>
          <article className="text-box right-bottom">
            <h2>{data.title2}</h2>
            <div dangerouslySetInnerHTML={{ __html: renderContent(data.text2) }} />
          </article>
        </section>
      </div>
      {isModalOpen && (
        <ModaleVisite
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={data}
          setData={setData}
        />
      )}
    </div>
  );
}

export default Visite;
