import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleHistoire.scss';

function ModaleHistoire({ isOpen, onClose, events, setEvents }) {
  const [mode, setMode] = useState('add');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [links, setLinks] = useState([{ label: '', url: '' }]);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    resetForm();
  }, [mode]);

  const resetForm = () => {
    setDate('');
    setDescription('');
    setPhoto('');
    setFile(null);
    setLinks([{ label: '', url: '' }]);
    setSelectedEvent(null);
  };

  const handleAdd = async () => {
    if (!date || !description || !file) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('date', date);
    formData.append('description', description);
    formData.append('photo', file);
    formData.append('links', JSON.stringify(links));

    try {
      const response = await fetch('http://localhost:5000/api/histoire/add', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      setEvents(data.data);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'événement:', error);
    }
  };

  const handleEdit = async () => {
    if (!selectedEvent) return;
    if (!date || !description || (!file && !photo)) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('originalDate', selectedEvent.date);
    formData.append('date', date);
    formData.append('description', description);
    if (file) {
      formData.append('photo', file);
    }
    formData.append('links', JSON.stringify(links));

    try {
      const response = await fetch('http://localhost:5000/api/histoire/update', {
        method: 'PUT',
        body: formData,
      });
      const data = await response.json();
      setEvents(data.data);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Erreur lors de la modification de l\'événement:', error);
    }
  };

  const handleDelete = async () => {
    if (!selectedEvent) return;

    try {
      const response = await fetch('http://localhost:5000/api/histoire/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ date: selectedEvent.date }),
      });
      const data = await response.json();
      setEvents(data.data);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'événement:', error);
    }
  };

  const handleSelectChange = (e) => {
    const selectedDate = e.target.value;
    const selected = events.find(event => event.date === selectedDate);
    setSelectedEvent(selected);
    if (selected) {
      setDate(selected.date);
      setDescription(selected.description);
      setPhoto(selected.photo);
      setLinks(selected.links || [{ label: '', url: '' }]);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  const handleDateChange = (e) => {
    let value = e.target.value;
  
    // Retirer tout ce qui n'est pas un chiffre ou une barre oblique
    value = value.replace(/[^0-9\/]/g, '');
  
    // Ajouter automatiquement la barre oblique après deux chiffres (MM)
    if (value.length === 2 && !value.includes('/')) {
        value = value + '/';
    }
  
    // Valider si le format correspond à MM/YYYY
    if (/^\d{0,2}(\/\d{0,4})?$/.test(value)) {
        setDate(value);
    }
  };
  

  const addLink = () => {
    setLinks([...links, { label: '', url: '' }]);
  };

  const removeLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'add' ? 'active' : ''} onClick={() => setMode('add')}>Ajouter</button>
            <button className={mode === 'edit' ? 'active' : ''} onClick={() => setMode('edit')}>Modifier</button>
            <button className={mode === 'delete' ? 'active' : ''} onClick={() => setMode('delete')}>Supprimer</button>
          </div>
          <div className="modale-body">
            {mode === 'add' && (
              <>
                <input 
                  type="text" 
                  placeholder="Date (MM/YYYY)" 
                  value={date} 
                  onChange={handleDateChange} 
                  required 
                  maxLength="7"
                />
                <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                <input type="file" accept="image/*" onChange={handleFileChange} required />

                {links.map((link, index) => (
                  <div key={index} className="link-group">
                    <input
                      type="text"
                      placeholder="Label"
                      value={link.label}
                      onChange={(e) => handleLinkChange(index, 'label', e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      placeholder="Lien URL"
                      value={link.url}
                      onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                      required
                    />
                    <button onClick={() => removeLink(index)}>Supprimer</button>
                  </div>
                ))}
                <button onClick={addLink}>Ajouter un lien</button>
                <button onClick={handleAdd}>Ajouter</button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <select value={selectedEvent ? selectedEvent.date : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner un événement</option>
                  {events.map((event, index) => (
                    <option key={index} value={event.date}>{event.date}</option>
                  ))}
                </select>
                {selectedEvent && (
                  <>
                    <input 
                      type="text" 
                      placeholder="Date (MM/YYYY)" 
                      value={date} 
                      onChange={handleDateChange} 
                      required 
                      maxLength="7"
                    />
                    <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                    <input type="file" accept="image/*" onChange={handleFileChange} />

                    {links.map((link, index) => (
                      <div key={index} className="link-group">
                        <input
                          type="text"
                          placeholder="Label"
                          value={link.label}
                          onChange={(e) => handleLinkChange(index, 'label', e.target.value)}
                          required
                        />
                        <input
                          type="text"
                          placeholder="Lien URL"
                          value={link.url}
                          onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                          required
                        />
                        <button onClick={() => removeLink(index)}>Supprimer</button>
                      </div>
                    ))}
                    <button onClick={addLink}>Ajouter un lien</button>
                    <button onClick={handleEdit}>Modifier</button>
                  </>
                )}
              </>
            )}
            {mode === 'delete' && (
              <>
                <select value={selectedEvent ? selectedEvent.date : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner un événement</option>
                  {events.map((event, index) => (
                    <option key={index} value={event.date}>{event.date}</option>
                  ))}
                </select>
                {selectedEvent && <button onClick={handleDelete}>Supprimer</button>}
              </>
            )}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleHistoire;
