import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleMentions.scss';

function ModaleMentions({ isOpen, onClose, mentions, setMentions }) {
  const [mode, setMode] = useState('add');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [date, setDate] = useState('');
  const [source, setSource] = useState('');
  const [category, setCategory] = useState('article');
  const [imageUrl, setImageUrl] = useState('');
  const [selectedMention, setSelectedMention] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    resetForm();
  }, [mode]);

  const resetForm = () => {
    setTitle('');
    setUrl('');
    setDate('');
    setSource('');
    setCategory('article');
    setImageUrl('');
    setFile(null);
    setSelectedMention(null);
  };

  const handleDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 2 && value.length <= 4) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    } else if (value.length > 4) {
      value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
    }
    setDate(value);
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleAdd = () => {
    if (!title || !url || !date || !source || (category === 'article' && !file)) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('titre', title);
    formData.append('url', url);
    formData.append('date', date);
    formData.append('source', source);
    formData.append('category', category);
    if (file) {
      formData.append('image', file);
    }

    fetch('http://localhost:5000/api/mentions/add', {
      method: 'POST',
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        setMentions(data.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Erreur lors de l\'ajout de la mention:', error));
  };

  const handleEdit = () => {
    if (!selectedMention) return;
    if (!title || !url || !date || !source || (category === 'article' && !imageUrl && !file)) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('originalTitle', selectedMention.titre);
    formData.append('titre', title);
    formData.append('url', url);
    formData.append('date', date);
    formData.append('source', source);
    formData.append('category', category);
    if (file) {
      formData.append('image', file);
    }

    fetch('http://localhost:5000/api/mentions/update', {
      method: 'PUT',
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        setMentions(data.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Erreur lors de la modification de la mention:', error));
  };

  const handleDelete = () => {
    if (!selectedMention) return;

    fetch('http://localhost:5000/api/mentions/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ titre: selectedMention.titre }),
    })
      .then(res => res.json())
      .then(data => {
        setMentions(data.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Erreur lors de la suppression de la mention:', error));
  };

  const handleSelectChange = (e) => {
    const selectedTitle = e.target.value;
    const selected = mentions.find(mention => mention.titre === selectedTitle);
    setSelectedMention(selected);
    if (selected) {
      setTitle(selected.titre);
      setUrl(selected.url);
      setDate(selected.date);
      setSource(selected.source);
      setCategory(selected.category);
      setImageUrl(selected.imageUrl);
      setFile(null);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'add' ? 'active' : ''} onClick={() => setMode('add')}>Ajouter</button>
            <button className={mode === 'edit' ? 'active' : ''} onClick={() => setMode('edit')}>Modifier</button>
            <button className={mode === 'delete' ? 'active' : ''} onClick={() => setMode('delete')}>Supprimer</button>
          </div>
          <div className="modale-body">
            {mode !== 'add' && (
              <select value={selectedMention ? selectedMention.titre : ''} onChange={handleSelectChange} required>
                <option value="">Sélectionner une mention</option>
                {mentions.map((mention, index) => (
                  <option key={index} value={mention.titre}>{mention.titre}</option>
                ))}
              </select>
            )}
            {(mode === 'add' || (mode === 'edit' && selectedMention)) && (
              <>
                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                  <option value="article">Article</option>
                  <option value="video">Vidéo</option>
                </select>
                <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                <input type="text" placeholder="URL" value={url} onChange={(e) => setUrl(e.target.value)} required/>
                <input
                  type="text"
                  placeholder="Date (JJ/MM/AAAA)"
                  value={date}
                  onChange={handleDateChange}
                  maxLength={10}
                  required
                />
                <input type="text" placeholder="Source" value={source} onChange={(e) => setSource(e.target.value)} required/>
                {category === 'article' && (
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleImageUpload} 
                    required={!imageUrl}
                  />
                )}
              </>
            )}
            {mode === 'add' && <button onClick={handleAdd}>Ajouter</button>}
            {mode === 'edit' && selectedMention && <button onClick={handleEdit}>Modifier</button>}
            {mode === 'delete' && selectedMention && <button onClick={handleDelete}>Supprimer</button>}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleMentions;
