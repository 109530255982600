import React, { useState, useEffect } from 'react';
import './mentions.scss';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import mentionsData from '../../data/mentions.json';
import ModaleMentions from '../../components/modaleMentions/modaleMentions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';


function Mentions() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mentions, setMentions] = useState(mentionsData);

  const getThumbnailUrl = (mention) => {
    if (mention.category === "video") {
      const videoId = mention.url.split("v=")[1] || mention.url.split("/").pop();
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    } 
    return mention.imageUrl;
  };

  const getActionText = (category) => {
    return category === "video" ? "Regarder la vidéo" : "Lire l'article";
  };

  const sortedMentions = mentions.sort((a, b) => {
    const dateA = new Date(a.date.split('/').reverse().join('-'));
    const dateB = new Date(b.date.split('/').reverse().join('-'));
    return dateB - dateA;
  });

  return (
    <div className="mentions">
      <SectionTitle id="mentions" />
      <section className="mentions-content">
        {sortedMentions.map((mention, index) => (
          <div key={index} className="mention-card">
            <div 
              className="mention-thumbnail" 
              style={{ backgroundImage: `url(${getThumbnailUrl(mention)})` }} 
              role="img" 
              aria-label={mention.titre}>
            </div>
            <div className="mention-info">
                <div className='titre-date'>
                  <h3>{mention.titre}</h3>
                  <p>{mention.date} - {mention.source}</p>
                </div>
              <a href={mention.url} target="_blank" rel="noopener noreferrer">
                {getActionText(mention.category)}
              </a>
            </div>
          </div>
        ))}
        {window.location.hostname === 'localhost' && (
          <button onClick={() => setIsModalOpen(true)} className='btn-modifier'>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
      </section>
      {isModalOpen && (
        <ModaleMentions
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mentions={mentions}
          setMentions={setMentions}
        />
      )}
    </div>
  );
}

export default Mentions;
