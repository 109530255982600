import React, { useState } from 'react';
import './sectionTitle.scss';
import sectionTitles from '../../data/sectionTitle.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFloppyDisk, faTimes } from '@fortawesome/free-solid-svg-icons';

function SectionTitle({ id }) {
  const sectionData = sectionTitles.find(section => section.id === id);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(sectionData?.title || '');
  const [editedText, setEditedText] = useState(sectionData?.text || '');

  if (!sectionData) {
    return null;
  }

  const isLocalEnv = window.location.hostname === 'localhost';

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const updatedSection = {
      id,
      title: editedTitle,
      text: editedText.replace(/\n/g, '<br />') // Remplacer les retours à la ligne par <br />
    };

    fetch('http://localhost:5000/api/sectionTitle/update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedSection)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Section mise à jour avec succès:', data);
      setIsEditing(false);
    })
    .catch(error => {
      console.error('Erreur lors de la mise à jour de la section:', error);
    });
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedTitle(sectionData.title);
    setEditedText(sectionData.text.replace(/<br \/>/g, '\n'));
  };

  return (
    <div className="section-title">
      {isEditing ? (
        <>
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            className="edit-input"
          />
          <textarea
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            className="edit-textarea"
          />
          <div className="controls">
            <button onClick={handleSaveClick} className="save-btn">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </button>
            <button onClick={handleCancelClick} className="cancel-btn">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </>
      ) : (
        <>
          <div>
            {isLocalEnv && (
              <button onClick={handleEditClick} className="edit-btn">
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
            {sectionData.title && <h1>{sectionData.title}</h1>}
          </div>
          {sectionData.text && (
            <p dangerouslySetInnerHTML={{ __html: sectionData.text }}></p>
          )}
        </>
      )}
    </div>
  );
}

export default SectionTitle;
