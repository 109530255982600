import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import './header.scss';
import contactInfo from '../../data/contactInfo.json';

function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleTitleClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className='header'>
      <div className="header-logo-title" onClick={handleTitleClick}>
        <img src={contactInfo.logo} alt="Logo de La Houblonnière de Lezerzot" className="logo" />
        <h1 className="title">
          La Houblonnière de Lezerzot
        </h1>
        <h2 className='subtitle-mobile'>Éthique - Local - Indépendant</h2>
      </div>
      <nav className='nav'>
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="#">La ferme</Link>
            <ul className="dropdown">
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/nos-valeurs">Nos valeurs</Link></li>
              <li><Link to="/about">Qui sommes-nous ?</Link></li>
              <li><Link to="/mentions">On parle de nous</Link></li>
              <li><Link to="/histoire">Notre histoire</Link></li>
              <li><Link to="/visite">Venez nous rendre visite</Link></li>
            </ul>
          </li>
          <li className="nav-item">
            <Link to="/houblon">Houblons Bretons</Link>
          </li>
          <li className="nav-item">
            <Link to="#">Nos Moutons</Link>
            <ul className="dropdown">
              <li><Link to="/mouton">La race Belle-île</Link></li>
              <li><Link to="/produit">Nos produits</Link></li>
            </ul>
          </li>
          <li className="nav-item">
            <Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
      <div className={`mobile-menu ${isMenuOpen ? 'big' : 'small'}`}>
        <div className='menu-buttons'>
        <button aria-label="Ouvrir le menu" className={`iconMenu ${isMenuOpen ? 'invisible' : 'visible'}`} onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars}/>
        </button>
        <button aria-label="Fermer le menu" className={`iconCloseMenu ${isMenuOpen ? 'visible' : 'invisible'}`} onClick={closeMenu}>
          <FontAwesomeIcon icon={faXmark}/>
        </button>        
        </div>
        <div className={`menu-bar ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Accueil</Link></li>
            <li><span className='section'>Nos services</span></li>
            <li><Link to="/houblon" onClick={closeMenu}>Nos houblons</Link></li>
            <li><Link to="/produit" onClick={closeMenu}>Nos produits</Link></li>
            <li><Link to="/mouton" onClick={closeMenu}>La race Belle-Île</Link></li>
            <li><Link to="/visite" onClick={closeMenu}>Rendez nous visite</Link></li>
            <li><span className='section'>A propos de nous</span></li>
            <li><Link to="/nos-valeurs" onClick={closeMenu}>Nos valeurs</Link></li>
            <li><Link to="/about" onClick={closeMenu}>Qui sommes-nous ?</Link></li>
            <li><Link to="/mentions" onClick={closeMenu}>On parle de nous</Link></li>
            <li><Link to="/histoire" onClick={closeMenu}>Notre histoire</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
