import React, { useState, useEffect } from 'react';
import './accueil.scss';
import { Link } from 'react-router-dom';
import FacebookVitrine from '../../components/Facebook';
import AnnonceAccueil from '../../components/annonceAccueil/annonceAccueil';
import accueilData from '../../data/accueil.json';
import ModaleAccueil from '../../components/modaleAccueil/modaleAccueil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Accueil() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  const [activeSection, setActiveSection] = useState(null);
  const [isModaleOpen, setIsModaleOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const handleMouseEnter = (section) => {
    setActiveSection(section);
  };

  const handleMouseLeave = () => {
    setActiveSection(null);
  };

  const handleOpenModale = (section) => {
    setSelectedSection(section);
    setIsModaleOpen(true);
  };

  const handleCloseModale = () => {
    setIsModaleOpen(false);
    setSelectedSection(null);
  };

  const isLocalEnv = window.location.hostname === 'localhost';

  const fermeData = accueilData.sections.find(section => section.id === 'ferme');
  const moutonData = accueilData.sections.find(section => section.id === 'mouton');
  const houblonData = accueilData.sections.find(section => section.id === 'houblon');
  const visiteData = accueilData.sections.find(section => section.id === 'visite');

  const renderText = (text) => {
    if (Array.isArray(text)) {
      return text.map((paragraph, index) => (
        <p key={index}>{paragraph.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))}</p>
      ));
    }
    return text.split('\n').map((line, i) => (
      <p key={i}>
        {line}
        <br />
      </p>
    ));
  };

  return (
    <div className='accueil__div'>
      <AnnonceAccueil />
      <div className='puzzle'>
        {isLocalEnv && (
            <button className='btn-modifier' onClick={() => handleOpenModale('ferme')}>
              <FontAwesomeIcon icon={faPen} />
            </button>
          )}
        <section
          className='accueil-ferme'
          onMouseEnter={() => handleMouseEnter('ferme')}
          onMouseLeave={handleMouseLeave}
        >
          <div className='titre'>
            <h2>{fermeData.title}</h2>
          </div>
          <div className='filtre'></div>
          <img src={fermeData.image} alt={fermeData.title} />
          <div className='details'>
            {renderText(fermeData.text)}
            <p>
              {fermeData.links.map((link, index) => (
                <span key={index}>
                  {activeSection === 'ferme' ? (
                    <Link to={link.url}>{link.label}</Link>
                  ) : (
                    link.label
                  )}
                  {index < fermeData.links.length - 1 && ', '}
                </span>
              ))}
            </p>
          </div>
        </section>
        <section className='accueil-moutonHoublon'>
          <article
            className='accueil-mouton'
            onMouseEnter={() => handleMouseEnter('mouton')}
            onMouseLeave={handleMouseLeave}
          >
            <div className='titre'>
              <h2>{moutonData.title}</h2>
            </div>
            <div className='filtre'></div>
            <img src={moutonData.image} alt={moutonData.title} />
            <div className='details'>
              {renderText(moutonData.text)}
              <p>
                {moutonData.links.map((link, index) => (
                  <span key={index}>
                    {activeSection === 'mouton' ? (
                      <Link to={link.url}>{link.label}</Link>
                    ) : (
                      link.label
                    )}
                    {index < moutonData.links.length - 1 && ', '}
                  </span>
                ))}
              </p>
            </div>
          </article>
          <article
            className='accueil-houblon'
            onMouseEnter={() => handleMouseEnter('houblon')}
            onMouseLeave={handleMouseLeave}
          >
            <div className='titre'>
              <h2>{houblonData.title}</h2>
            </div>
            <div className='filtre'></div>
            <img src={houblonData.image} alt={houblonData.title} />
            <div className='details'>
              {renderText(houblonData.text)}
              <p>
                {houblonData.links.map((link, index) => (
                  <span key={index}>
                    {activeSection === 'houblon' ? (
                      <Link to={link.url}>{link.label}</Link>
                    ) : (
                      link.label
                    )}
                  </span>
                ))}
              </p>
            </div>
          </article>
        </section>
        <section
          className='accueil-visite'
          onMouseEnter={() => handleMouseEnter('visite')}
          onMouseLeave={handleMouseLeave}
        >
          <div className='titre'>
            <h2>{visiteData.title}</h2>
          </div>
          <div className='filtre'></div>
          <img src={visiteData.image} alt={visiteData.title} />
          <div className='details'>
            {renderText(visiteData.text)}
            {activeSection === 'visite' && (
              <Link to={visiteData.links[0].url}>{visiteData.links[0].label}</Link>
            )}
          </div>
        </section>
      </div>
      <div className='facebook__div'>
        <FacebookVitrine />
      </div>
      {isModaleOpen && (
        <ModaleAccueil 
          isOpen={isModaleOpen} 
          onClose={handleCloseModale} 
          data={accueilData.sections} 
          setData={(updatedSections) => {
            const updatedData = { sections: updatedSections };
          }} 
        />
      )}
    </div>
  );
}

export default Accueil;
