import React, { useState, useEffect } from 'react';
import './banniere.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFloppyDisk, faTimes, faImage } from '@fortawesome/free-solid-svg-icons';
import staticBanniere from '../../data/banniere.json';

function Banniere() {
  const [image, setImage] = useState(staticBanniere.image);
  const [preview, setPreview] = useState(staticBanniere.image);
  const [title, setTitle] = useState(staticBanniere.title);
  const [subtitle, setSubtitle] = useState(staticBanniere.subtitle);
  const [cadreImage, setCadreImage] = useState(staticBanniere.cadreImage);
  const [isEditing, setIsEditing] = useState(false);

  const isLocalEnv = window.location.hostname === 'localhost';

  useEffect(() => {
    document.documentElement.style.setProperty('--cadre-image-position', `${cadreImage}%`);
  }, [cadreImage]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('cadreImage', cadreImage);
    
    if (image && typeof image === 'object') {
      formData.append('image', image);
    }
    
    fetch('http://localhost:5000/api/banniere/update', {
      method: 'PUT',
      body: formData,
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error('Failed to update banniere');
      }
    })
    .then((data) => {
      console.log('Bannière mise à jour avec succès:', data);
      setIsEditing(false);
    })
    .catch((error) => {
      console.error('Erreur lors de la mise à jour de la bannière:', error);
    });
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setTitle(staticBanniere.title);
    setSubtitle(staticBanniere.subtitle);
    setImage(staticBanniere.image);
    setPreview(staticBanniere.image);
    setCadreImage(staticBanniere.cadreImage);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCadreImageChange = (e) => {
    const value = e.target.value;
    if (/^\d{1,2}$/.test(value) && value >= 0 && value <= 100) {
      setCadreImage(value);
    }
  };

  return (
    <div className="banniere">
      {isLocalEnv && (
        <>
          {isEditing ? (
            <button onClick={handleSaveClick} className="saveBanniere">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </button>
          ) : (
            <button onClick={handleEditClick} className="modifierBanniere">
              <FontAwesomeIcon icon={faPen} />
            </button>
          )}
          {isEditing && (
            <button onClick={handleCancelClick} className="cancelBanniere">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </>
      )}
      <div className='banniere-image'>
        <div className='overlay'></div>
        <img src={preview} alt="Paysage de la houblonnière de Lezerzot" className={`cadreImage`}/>
        {isEditing && (
          <>
            <label htmlFor="file-upload" className="custom-file-upload">
              <FontAwesomeIcon icon={faImage} />
            </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="file-input"
              id="file-upload"
            />
            <input
              type="number"
              value={cadreImage}
              onChange={handleCadreImageChange}
              className='inputCadreBanniere'
              min="0"
              max="100"
              placeholder="Cadre (%)"
            />
          </>
        )}
        <div className="banniere-text">
          {isEditing ? (
            <>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="edit-input"
                placeholder="Titre"
                required
              />
              <input
                type="text"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
                className="edit-subtitle"
                placeholder="Sous-titre"
                required
              />
            </>
          ) : (
            <>
              <h1 className='banniere__title'>{title}</h1>
              <p className='banniere__p'>{subtitle}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Banniere;
