import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleAccueil.scss';

function ModaleAccueil({ isOpen, onClose, data, setData }) {
  const [mode, setMode] = useState('bloc1');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState(null);
  const [links, setLinks] = useState([{ label: '', url: '' }]);

  useEffect(() => {
    if (isOpen) {
      const section = data[getSectionIndex(mode)];
      setTitle(section.title);
      setText(section.text);
      setPhoto(section.image);
      setLinks(section.links);
    }
  }, [isOpen, mode, data]);

  const getSectionIndex = (mode) => {
    const modes = ['bloc1', 'bloc2', 'bloc3', 'bloc4'];
    return modes.indexOf(mode);
  };

  const resetForm = () => {
    setTitle('');
    setText('');
    setPhoto('');
    setFile(null);
    setLinks([{ label: '', url: '' }]);
  };

  const handleSave = () => {
    const formData = new FormData();
    const updatedData = {
      title,
      text,
      image: file ? URL.createObjectURL(file) : photo,
      links,
      section: mode,
    };

    formData.append('title', title);
    formData.append('text', text);
    if (file) {
      formData.append('image', file);
    }
    formData.append('links', JSON.stringify(links));
    formData.append('section', mode);

    fetch('http://localhost:5000/api/accueil/update', {
      method: 'PUT',
      body: formData,
    })
      .then((res) => res.json())
      .then((updatedData) => {
        const newData = [...data];
        newData[getSectionIndex(mode)] = updatedData.data;
        setData(newData);
        resetForm();
        onClose();
      })
      .catch((error) => console.error('Error updating section:', error));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  const addLink = () => {
    setLinks([...links, { label: '', url: '' }]);
  };

  const removeLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'bloc1' ? 'active' : ''} onClick={() => setMode('bloc1')}>Bloc 1</button>
            <button className={mode === 'bloc2' ? 'active' : ''} onClick={() => setMode('bloc2')}>Bloc 2</button>
            <button className={mode === 'bloc3' ? 'active' : ''} onClick={() => setMode('bloc3')}>Bloc 3</button>
            <button className={mode === 'bloc4' ? 'active' : ''} onClick={() => setMode('bloc4')}>Bloc 4</button>
          </div>
          <div className="modale-body">
            <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required />
            <textarea placeholder="Contenu" value={text} onChange={(e) => setText(e.target.value)} required />
            <input type="file" accept="image/*" onChange={handleFileChange} />

            {links.map((link, index) => (
              <div key={index} className="link-group">
                <input
                  type="text"
                  placeholder="Label"
                  value={link.label}
                  onChange={(e) => handleLinkChange(index, 'label', e.target.value)}
                  required
                />
                <select
                  value={link.url}
                  onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                  required
                >
                  <option value="">Sélectionnez un lien</option>
                  <option value="/houblon">Houblons bretons</option>
                  <option value="/produit">Produits</option>
                  <option value="/visite">Rendez-nous Visite</option>
                  <option value="/mouton">La race Belle Île</option>
                  <option value="/mentions">On parle de nous</option>
                  <option value="/contact">Contact</option>
                </select>
                <button onClick={() => removeLink(index)}>Supprimer</button>
              </div>
            ))}
            <button onClick={addLink}>Ajouter un lien</button>
            <button onClick={handleSave}>Modifier</button>
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleAccueil;
