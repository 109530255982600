import React, { useState, useRef, useEffect } from 'react';
import './mouton.scss';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import cardData from '../../data/mouton.json';
import ModaleMouton from '../../components/modaleMouton/modaleMouton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { marked } from 'marked';

function Mouton() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [expandedCard, setExpandedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cards, setCards] = useState(cardData);

  const toggleExpand = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  return (
    <div className="mouton">
      <SectionTitle id="mouton" />
      <section className="mouton-content">
        {cards.map((item, index) => (
          <Card
            key={index}
            item={item}
            isExpanded={expandedCard === index}
            onClick={() => toggleExpand(index)}
            className={item.className}
          />
        ))}
        {window.location.hostname === 'localhost' && (
          <button onClick={() => setIsModalOpen(true)} className='btn-modifier'>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
      </section>
      {isModalOpen && (
        <ModaleMouton
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          cardData={cards}
          setCardData={setCards}
        />
      )}
    </div>
  );
}

function Card({ item, isExpanded, onClick }) {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <article className={`mouton-card ${isExpanded ? 'expanded' : ''}`} onClick={onClick}>
      <div 
        className="mouton-image" 
        style={{ 
          backgroundImage: `url(${item.imageUrl})`, 
          backgroundPosition: `center ${item.imagePosition}%`
        }} 
        role="img" 
        aria-label={item.title}
      ></div>
      <div className="mouton-title">
        <h2>{item.title}</h2>
        <div className="mouton-text" style={{ height: isExpanded ? height : 0 }} ref={contentRef}>
          <div dangerouslySetInnerHTML={{ __html: marked(item.text) }}></div>
          {item.lien && <a href={item.lien}>{item.lienText}</a>}
        </div>
      </div>
    </article>
  );
}

export default Mouton;
