import React from 'react';
import './footer.scss';
import contactInfo from '../../data/contactInfo.json';

function Footer() {
  const formatTelephoneForLink = (telephone) => {
    return telephone.replace(/[.\s]/g, '');
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Contact</h3>
          <p>Email: <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></p>
          <p>Téléphone: <a href={`tel:${formatTelephoneForLink(contactInfo.telephone)}`}>{contactInfo.telephone}</a></p>
        </div>
        <div className="footer-section">
          <h3>Adresse</h3>
          <p>
            <a 
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(contactInfo.adresse)}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {contactInfo.adresse}
            </a>
          </p>
        </div>
        <div className="footer-section">
          <h3>Suivez-nous</h3>
          <p>
            <a href={contactInfo.facebook} target="_blank" rel="noopener noreferrer">Facebook</a>
          </p>
          <p>
            <a href={contactInfo.instagram} target="_blank" rel="noopener noreferrer">Instagram</a>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 La Houblonnière de Lezerzot. Tous droits réservés.</p>
      </div>
    </footer>
  );
}

export default Footer;
