import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './notFound.scss';

function NotFound() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <main className="not-found">
      <section>
        <h1>Oops ! La page que vous cherchez n'existe pas...</h1>
        <h2>Erreur 404</h2>
        <img src='assets/404/mouton.webp' alt="Illustration d'un mouton désorienté" />
        <Link to="/" className="btn-home">Retour à l'accueil</Link>
      </section>
    </main>
  );
}

export default NotFound;
