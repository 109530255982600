import React, { useState, useRef, useEffect } from 'react';
import './valeurs.scss';
import Bandeau from '../../components/bandeau/bandeau';
import bandeauxData from '../../data/bandeaux.json';
import valeursData from '../../data/valeurs.json';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import ModaleValeurs from '../../components/modaleValeurs/modaleValeurs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { marked } from 'marked';

function NosValeurs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const bandeauData = bandeauxData.find(b => b.id === 'valeurs');

  const [activeDropdown, setActiveDropdown] = useState(null);
  const contentRefs = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valeurs, setValeurs] = useState(valeursData);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((content, index) => {
      if (content) {
        content.style.maxHeight = activeDropdown === index ? `${content.scrollHeight}px` : '0px';
      }
    });
  }, [activeDropdown]);

  const getClassNameById = (id) => {
    return id % 2 === 0 ? 'left' : 'right';
  };

  return (
    <div className="nos-valeurs">
      {bandeauData && (
        <Bandeau
          id={bandeauData.id}
          title={bandeauData.title}
          text={bandeauData.text}
          imageUrl={bandeauData.imageUrl}
          imagePosition={bandeauData.imagePosition}
          cadreImage={bandeauData.cadreImage}
        />
      )}
      <SectionTitle id="valeur" />

      <div className="dropdowns">
        {window.location.hostname === 'localhost' && (
          <button onClick={() => setIsModalOpen(true)} className='btn-modifier'>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
        {valeurs.map((item, index) => (
          <Dropdown
            key={item.id}
            title={item.title}
            content={marked(item.content)}
            isExpanded={activeDropdown === index}
            onClick={() => toggleDropdown(index)}
            contentRef={el => contentRefs.current[index] = el}
            className={getClassNameById(item.id)}
          />
        ))}
      </div>
      {isModalOpen && (
        <ModaleValeurs
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          valeurs={valeurs}
          setValeurs={setValeurs}
        />
      )}
    </div>
  );
}

function Dropdown({ title, content, isExpanded, onClick, contentRef, className }) {
  return (
    <section className={`dropdown ${isExpanded ? 'expanded' : ''} ${className}`} onClick={onClick}>
      <h3 className='title'>{title}</h3>
      <div className="content" ref={contentRef}>
        <div className="content-inner" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </section>
  );
}

export default NosValeurs;
