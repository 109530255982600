import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleVisite.scss';
import contactInfo from '../../data/contactInfo.json';

function ModaleVisite({ isOpen, onClose, data, setData }) {
  const [mode, setMode] = useState('bloc1');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isOpen) {
      if (mode === 'bloc1') {
        setTitle(data.title1);
        setText(data.text1);
        setPhoto(data.photo1);
      } else if (mode === 'bloc2') {
        setTitle(data.title2);
        setText(data.text2);
        setPhoto(data.photo2);
      }
    }
  }, [isOpen, mode, data]);

  const resetForm = () => {
    setTitle('');
    setText('');
    setPhoto('');
    setFile(null);
  };

  const handleSave = () => {
    const formData = new FormData();
    if (mode === 'bloc1') {
      formData.append('title1', title);
      formData.append('text1', text);
      if (file) {
        formData.append('photo1', file);
      }
    } else if (mode === 'bloc2') {
      formData.append('title2', title);
      formData.append('text2', text);
      if (file) {
        formData.append('photo2', file);
      }
    }

    fetch('http://localhost:5000/api/visite/update', {
      method: 'PUT',
      body: formData,
    })
      .then(res => res.json())
      .then(updatedData => {
        setData(updatedData.data);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error updating visite:', error));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPhoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'bloc1' ? 'active' : ''} onClick={() => setMode('bloc1')}>Bloc 1</button>
            <button className={mode === 'bloc2' ? 'active' : ''} onClick={() => setMode('bloc2')}>Bloc 2</button>
          </div>
          <div className="modale-body">
            <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required />
            <textarea placeholder="Contenu" value={text} onChange={(e) => setText(e.target.value)} required />
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <button onClick={handleSave}>Modifier</button>
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleVisite;
