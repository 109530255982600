import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import Banniere from './components/banniere/banniere';
import Footer from './components/footer/footer';
import About from './pages/about/about';
import Accueil from './pages/accueil/accueil';
import Contact from './pages/contact/contact';
import Histoire from './pages/histoire/histoire';
import Houblon from './pages/houblon/houblon';
import Mouton from './pages/mouton/mouton';
import Produit from './pages/mouton/produit';
import Visite from './pages/visite/visite';
import NosValeurs from './pages/valeurs/valeurs';
import NotFound from './pages/notFound/notFound';
import BandeauAdmin from './components/bandeauAdmin/bandeauAdmin';
import Mentions from './pages/mentions/mentions';

function App() {
  const isLocalEnv = window.location.hostname === 'localhost';

  return (
    <Router>
      <div>
        {isLocalEnv && <BandeauAdmin />}
        <Header />
        <Banniere />
        <main>
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/nos-valeurs" element={<NosValeurs />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/histoire" element={<Histoire />} />
            <Route path="/houblon" element={<Houblon />} />
            <Route path="/mouton" element={<Mouton />} />
            <Route path="/produit" element={<Produit />} />
            <Route path="/visite" element={<Visite />} />
            <Route path="/mentions" element={<Mentions />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
