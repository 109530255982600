import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleHoublon.scss';

function ModaleHoublon({ isOpen, onClose }) {
  const [mode, setMode] = useState('add');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [aa, setAa] = useState('');
  const [ab, setAb] = useState('');
  const [year, setYear] = useState('2023');
  const [stock, setStock] = useState(true);
  const [selectedVariety, setSelectedVariety] = useState(null);
  const [varieties, setVarieties] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetch('http://localhost:5000/api/houblons')
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setVarieties(data);
          } else {
            console.error('Données inattendues:', data);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }

    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setName('');
    setDescription('');
    setAa('');
    setAb('');
    setYear('2023');
    setStock(true);
    setSelectedVariety(null);
  };

  const handleAdd = () => {
    const newVariety = { name, description, aa, ab, year, stock };
    fetch('http://localhost:5000/api/houblons/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newVariety),
    })
      .then((res) => res.json())
      .then((data) => {
        setVarieties([...varieties, { ...newVariety, id: data.id }]);
        resetForm();
        onClose();
      })
      .catch((error) => console.error('Error adding variety:', error));
  };

  const handleEdit = () => {
    if (!selectedVariety) return;

    const updatedVariety = { id: selectedVariety.id, name, description, aa, ab, year, stock };
    fetch(`http://localhost:5000/api/houblons/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedVariety),
    })
      .then((res) => res.json())
      .then((data) => {
        setVarieties(
          varieties.map((v) => (v.id === selectedVariety.id ? data : v))
        );
        resetForm();
        onClose();
      })
      .catch((error) => console.error('Error updating variety:', error));
  };

  const handleDelete = () => {
    if (!selectedVariety) return;

    fetch(`http://localhost:5000/api/houblons/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: selectedVariety.id }),
    })
      .then((res) => res.json())
      .then(() => {
        setVarieties(varieties.filter((v) => v.id !== selectedVariety.id));
        resetForm();
        onClose();
      })
      .catch((error) => console.error('Error deleting variety:', error));
  };

  const handleSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    const selected = varieties.find(v => v.id === selectedId);
    setSelectedVariety(selected);
    if (selected) {
      setName(selected.name);
      setDescription(selected.description);
      setAa(selected.aa);
      setAb(selected.ab);
      setYear(selected.year);
      setStock(selected.stock);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    resetForm();
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button
              className={mode === 'add' ? 'active' : ''}
              onClick={() => handleModeChange('add')}
            >
              Ajouter
            </button>
            <button
              className={mode === 'edit' ? 'active' : ''}
              onClick={() => handleModeChange('edit')}
            >
              Modifier
            </button>
            <button
              className={mode === 'delete' ? 'active' : ''}
              onClick={() => handleModeChange('delete')}
            >
              Supprimer
            </button>
          </div>
          <div className="modale-body">
            {mode === 'add' && (
              <>
                <input
                  type="text"
                  placeholder="Nom du houblon"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <textarea
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={220}
                />
                <input
                  type="number"
                  placeholder="Taux AA"
                  value={aa}
                  onChange={(e) => setAa(e.target.value)}
                  step="0.01"
                />
                <input
                  type="number"
                  placeholder="Taux AB"
                  value={ab}
                  onChange={(e) => setAb(e.target.value)}
                  step="0.01"
                />
                <input
                  type="number"
                  placeholder="Année"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  min="2000"
                  max="2099"
                />
                <select
                  value={stock}
                  onChange={(e) => setStock(e.target.value === 'true')}
                >
                  <option value={true}>En stock</option>
                  <option value={false}>Rupture de stock</option>
                </select>
                <button onClick={handleAdd}>Ajouter</button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <select
                  value={selectedVariety ? selectedVariety.id : ''}
                  onChange={handleSelectChange}
                >
                  <option value="">Sélectionner un houblon</option>
                  {varieties.map((v) => (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
                {selectedVariety && (
                  <>
                    <input
                      type="text"
                      placeholder="Nom du houblon"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <textarea
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={220}
                    />
                    <input
                      type="number"
                      placeholder="Taux AA"
                      value={aa}
                      onChange={(e) => setAa(e.target.value)}
                      step="0.01"
                    />
                    <input
                      type="number"
                      placeholder="Taux AB"
                      value={ab}
                      onChange={(e) => setAb(e.target.value)}
                      step="0.01"
                    />
                    <input
                      type="number"
                      placeholder="Année"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      min="2000"
                      max="2099"
                    />
                    <select
                      value={stock}
                      onChange={(e) => setStock(e.target.value === 'true')}
                    >
                      <option value={true}>En stock</option>
                      <option value={false}>Rupture de stock</option>
                    </select>
                    <button onClick={handleEdit}>Modifier</button>
                  </>
                )}
              </>
            )}
            {mode === 'delete' && (
              <>
                <select
                  value={selectedVariety ? selectedVariety.id : ''}
                  onChange={handleSelectChange}
                >
                  <option value="">Sélectionner un houblon</option>
                  {varieties.map((v) => (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
                <button onClick={handleDelete}>Supprimer</button>
              </>
            )}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleHoublon;
