import React, { useEffect, useState } from 'react';
import './about.scss';
import Bandeau from '../../components/bandeau/bandeau';
import bandeauxData from '../../data/bandeaux.json';
import aboutData from '../../data/about.json';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import ModaleAbout from '../../components/modaleAbout/modaleAbout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function About() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [team, setTeam] = useState(aboutData.sort((a, b) => a.position - b.position));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const bandeauData = bandeauxData.find(b => b.id === 'about');

  return (
    <div className="about">
      {bandeauData && (
        <Bandeau
          id={bandeauData.id}
          title={bandeauData.title}
          text={bandeauData.text}
          imageUrl={bandeauData.imageUrl}
          imagePosition={bandeauData.imagePosition}
          cadreImage={bandeauData.cadreImage}
        />
      )}
      <SectionTitle id="about" />
      <div className="team">
        {window.location.hostname === 'localhost' && (
          <button onClick={() => setIsModalOpen(true)} className='btn-modifier'>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
        {team.map((member, index) => (
          <article className="team-member" key={index}>
            <div className="photo">
              <img src={member.photo} alt={`Portrait de ${member.name}`} className="portrait" />
            </div>
            <div className="info">
              <h3>{member.name}</h3>
              <p>{member.description}</p>
            </div>
          </article>
        ))}
      </div>
      {isModalOpen && (
        <ModaleAbout
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          team={team}
          setTeam={setTeam}
        />
      )}
    </div>
  );
}

export default About;
