import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './houblon.scss';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import staticVarieties from '../../data/houblon.json';
import bandeauxData from '../../data/bandeaux.json';
import Bandeau from '../../components/bandeau/bandeau';
import ModaleHoublon from '../../components/modaleHoublon/modaleHoublon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Houblon() {
  const isLocalEnv = window.location.hostname === 'localhost';
  const [isModaleOpen, setIsModaleOpen] = useState(false);

  const handleOpenModale = () => {
    setIsModaleOpen(true);
  };

  const handleCloseModale = () => {
    setIsModaleOpen(false);
  };

  const bandeauData = bandeauxData.find(b => b.id === 'houblon');

  return (
    <div className="houblon">
      {bandeauData && (
        <Bandeau
        id={bandeauData.id}
        title={bandeauData.title}
        text={bandeauData.text}
        imageUrl={bandeauData.imageUrl}
        imagePosition={bandeauData.imagePosition}
        cadreImage={bandeauData.cadreImage}
      />
      )}

      <section>
        <div className='title-button'>
        <SectionTitle id="houblon" />
          {isLocalEnv && (
          <button className='modifierHoublon' onClick={handleOpenModale}>
            <FontAwesomeIcon icon={faPen} />
          </button>
          )}
        </div>
        <div className="houblon-varieties">
          {staticVarieties.map((variety, index) => (
            <article key={index} className={`variety ${variety.stock ? '' : 'out-of-stock'}`}>
              <div className="variety-inner">
                <div className="variety-face variety-front">
                  <h3 className="variety-name">{variety.name}</h3>
                  <img src='assets/logo/houblon.webp' alt={`Icône représentant la variété de houblon ${variety.name}`} className='iconeHoublon'/>
                  {!variety.stock && <div className="stock-banner">RUPTURE DE STOCK</div>}
                  <div className='varietyAcide'>
                    <div className='aa'>
                      <span>AA : </span>
                      <span>{variety.aa ? `${variety.aa} %` : 'NN'}</span>
                    </div>
                    <div className='ab'>
                      <span>AB : </span>
                      <span>{variety.ab ? `${variety.ab} %` : 'NN'}</span>
                    </div>
                    <span className='taux'>(Taux {variety.year})</span>
                  </div>
                </div>
                <div className="variety-face variety-back">
                  <div className="variety-description">
                    <h4 className='varietyName'>{variety.name}</h4>
                    <p className='varietyDescription'>{variety.description}</p>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </section>
      <p className='infoSupp'>Les analyses d'huiles essentielles pour les variétés aromatiques peuvent être fournies sur demande.</p>
      <div className="order-button">
        <Link to="/contact" className="btn-commander">Commander</Link>
      </div>
      <ModaleHoublon isOpen={isModaleOpen} onClose={handleCloseModale} />
    </div>
  );
}

export default Houblon;
