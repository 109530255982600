import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFloppyDisk, faTimes, faImage } from '@fortawesome/free-solid-svg-icons';
import './bandeau.scss';

function Bandeau({ id, title, text, imageUrl, imagePosition, cadreImage }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedText, setEditedText] = useState(text);
  const [editedImageUrl, setEditedImageUrl] = useState(imageUrl);
  const [imageFile, setImageFile] = useState(null);
  const [editedCadreImage, setEditedCadreImage] = useState(cadreImage);

  const isLocalEnv = window.location.hostname === 'localhost';
  const maxLines = 13;

  const titleTextareaRef = useRef(null);

  useEffect(() => {
    if (titleTextareaRef.current) {
      titleTextareaRef.current.style.height = 'auto';
      titleTextareaRef.current.style.height = titleTextareaRef.current.scrollHeight + 'px';
    }
  }, [editedTitle]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('title', editedTitle);
    formData.append('text', editedText);
    formData.append('cadreImage', editedCadreImage);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    fetch('http://localhost:5000/api/bandeau/update', {
      method: 'PUT',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      setIsEditing(false);
      console.log('Bandeau mis à jour avec succès:', data);
    })
    .catch(error => {
      console.error('Erreur lors de la mise à jour du bandeau:', error);
    });
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedTitle(title);
    setEditedText(text);
    setEditedImageUrl(imageUrl);
    setImageFile(null);
    setEditedCadreImage(cadreImage);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    const lines = value.split('\n');
    if (lines.length <= maxLines) {
      setEditedText(value);
    } else {
      setEditedText(lines.slice(0, maxLines).join('\n'));
    }
  };

  const handleCadreImageChange = (e) => {
    const value = e.target.value;
    if (/^\d{1,2}$/.test(value) && value >= 0 && value <= 100) {
      setEditedCadreImage(value);
    }
  };

  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="bandeau">
      <div className="bandeau-content">
        <div className="bandeau-image">
          <img
            src={editedImageUrl}
            alt={`Bandeau pour illustrer ${editedTitle}`}
            className="cadreImage"
            style={{ objectPosition: `center ${editedCadreImage}%` }}
          />
          {isLocalEnv && (
            <div className="bandeau-controls">
              {isEditing ? (
                <>
                  <button onClick={handleSaveClick} className="saveBanniere">
                    <FontAwesomeIcon icon={faFloppyDisk} />
                  </button>
                  <button onClick={handleCancelClick} className="cancelBanniere">
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <FontAwesomeIcon icon={faImage} />
                  </label>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="file-input"
                    id="file-upload"
                  />
                  <input
                    type="number"
                    value={editedCadreImage}
                    onChange={handleCadreImageChange}
                    className='inputCadreBanniere'
                    min="0"
                    max="100"
                    placeholder="Cadre (%)"
                  />
                </>
              ) : (
                <button onClick={handleEditClick} className="modifierBandeauImg">
                  <FontAwesomeIcon icon={faPen} />
                </button>
              )}
            </div>
          )}
        </div>
        <div className={`bandeau-text ${imagePosition}`}>
          {isEditing ? (
            <>
              <textarea
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="edit-input"
                ref={titleTextareaRef}
                rows={1}
              />
              <textarea
                value={editedText}
                onChange={handleTextChange}
                className="edit-textarea"
              />
            </>
          ) : (
            <>
              <h1 className="bandeau-title">{editedTitle}</h1>
              <p>{formatTextWithLineBreaks(editedText)}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Bandeau;
