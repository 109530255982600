import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleProduits.scss';
import produitsData from '../../data/produits.json';

function ModaleProduits({ isOpen, onClose }) {
  const [mode, setMode] = useState('add');
  const [type, setType] = useState('agneau');
  const [name, setName] = useState('');
  const [prix, setPrix] = useState('');
  const [precision, setPrecision] = useState('');
  const [position, setPosition] = useState(1);
  const [selectedProduit, setSelectedProduit] = useState(null);
  const [produits, setProduits] = useState({ agneau: [], produitsArtisanaux: [] });
  const [editType, setEditType] = useState('agneau');
  const [deleteType, setDeleteType] = useState('agneau');

  useEffect(() => {
    setProduits(produitsData);
  }, []);

  const resetForm = () => {
    setType('agneau');
    setEditType('agneau');
    setDeleteType('agneau');
    setName('');
    setPrix('');
    setPrecision('');
    setPosition(1);
    setSelectedProduit(null);
  };

  const adjustPositions = (items) => {
    return items
      .sort((a, b) => a.position - b.position)
      .map((item, index) => ({ ...item, position: index + 1 }));
  };

  const swapPositions = (items, originalPosition, newPosition) => {
    const itemToMove = items.find(item => item.position === originalPosition);
    const itemToSwap = items.find(item => item.position === newPosition);

    if (itemToMove && itemToSwap) {
      itemToMove.position = newPosition;
      itemToSwap.position = originalPosition;
    }

    return items;
  };

  const handleAdd = () => {
    const newProduit = { nom: name, prix, precision, position: produits[type].length + 1 };
    fetch('http://localhost:5000/api/produits/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type, ...newProduit })
    })
      .then(res => res.json())
      .then(data => {
        const updatedProduits = {
          ...produits,
          [type]: adjustPositions([...produits[type], newProduit])
        };
        setProduits(updatedProduits);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error adding product:', error));
  };

  const handleEdit = () => {
    if (!selectedProduit) return;

    const updatedProduit = { nom: name, prix, precision, position: parseInt(position) };
    fetch('http://localhost:5000/api/produits/update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        originalType: editType,
        newType: type,
        originalNom: selectedProduit.nom,
        ...updatedProduit
      })
    })
      .then(res => res.json())
      .then(data => {
        const updatedProduits = { ...produits };
        if (editType === type) {
          if (selectedProduit.position !== position) {
            updatedProduits[type] = swapPositions(updatedProduits[type], selectedProduit.position, parseInt(position));
          }
          updatedProduits[type] = updatedProduits[type].map(p =>
            p.nom === selectedProduit.nom ? updatedProduit : p
          );
        } else {
          updatedProduits[editType] = updatedProduits[editType].filter(p => p.nom !== selectedProduit.nom);
          updatedProduits[type] = [...updatedProduits[type], updatedProduit];
        }
        updatedProduits[editType] = adjustPositions(updatedProduits[editType]);
        updatedProduits[type] = adjustPositions(updatedProduits[type]);
        setProduits(updatedProduits);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error updating product:', error));
  };

  const handleDelete = () => {
    if (!selectedProduit) return;

    fetch('http://localhost:5000/api/produits/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type: deleteType, nom: selectedProduit.nom })
    })
      .then(res => res.json())
      .then(data => {
        const updatedProduits = {
          ...produits,
          [deleteType]: adjustPositions(produits[deleteType].filter(p => p.nom !== selectedProduit.nom))
        };
        setProduits(updatedProduits);
        resetForm();
        onClose();
      })
      .catch(error => console.error('Error deleting product:', error));
  };

  const handleSelectChange = (e) => {
    const selectedName = e.target.value;
    const selected = produits[mode === 'edit' ? editType : deleteType].find(p => p.nom === selectedName);
    setSelectedProduit(selected);
    if (selected) {
      setName(selected.nom);
      setPrix(selected.prix);
      setPrecision(selected.precision);
      setPosition(selected.position);
      setType(editType);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    resetForm();
  };

  const renderPositionOptions = (type) => {
    const maxPosition = produits[type].length;
    const options = [];
    for (let i = 1; i <= maxPosition + (mode === 'add' ? 1 : 0); i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button
              className={mode === 'add' ? 'active' : ''}
              onClick={() => handleModeChange('add')}
            >
              Ajouter
            </button>
            <button
              className={mode === 'edit' ? 'active' : ''}
              onClick={() => handleModeChange('edit')}
            >
              Modifier
            </button>
            <button
              className={mode === 'delete' ? 'active' : ''}
              onClick={() => handleModeChange('delete')}
            >
              Supprimer
            </button>
          </div>
          <div className="modale-body">
            {mode === 'add' && (
              <>
                <select value={type} onChange={(e) => setType(e.target.value)}>
                  <option value="agneau">Agneau</option>
                  <option value="produitsArtisanaux">Produits artisanaux</option>
                </select>
                <input
                  type="text"
                  placeholder="Nom"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Prix"
                  value={prix}
                  onChange={(e) => setPrix(e.target.value)}
                />
                <textarea
                  placeholder="Précision"
                  value={precision}
                  onChange={(e) => setPrecision(e.target.value)}
                  maxLength={220}
                />
                <button onClick={handleAdd}>Ajouter</button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <select value={editType} onChange={(e) => setEditType(e.target.value)}>
                  <option value="agneau">Agneau</option>
                  <option value="produitsArtisanaux">Produits artisanaux</option>
                </select>
                <select value={selectedProduit ? selectedProduit.nom : ''} onChange={handleSelectChange}>
                  <option value="">Sélectionner un produit</option>
                  {produits[editType].map((p, index) => (
                    <option key={index} value={p.nom}>
                      {p.nom}
                    </option>
                  ))}
                </select>
                {selectedProduit && (
                  <>
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                      <option value="agneau">Agneau</option>
                      <option value="produitsArtisanaux">Produits artisanaux</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Nom"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Prix"
                      value={prix}
                      onChange={(e) => setPrix(e.target.value)}
                    />
                    <textarea
                      placeholder="Précision"
                      value={precision}
                      onChange={(e) => setPrecision(e.target.value)}
                      maxLength={220}
                    />
                    <select value={position} onChange={(e) => setPosition(e.target.value)}>
                      {renderPositionOptions(type)}
                    </select>
                    <button onClick={handleEdit}>Modifier</button>
                  </>
                )}
              </>
            )}
            {mode === 'delete' && (
              <>
                <select value={deleteType} onChange={(e) => setDeleteType(e.target.value)}>
                  <option value="agneau">Agneau</option>
                  <option value="produitsArtisanaux">Produits artisanaux</option>
                </select>
                <select value={selectedProduit ? selectedProduit.nom : ''} onChange={handleSelectChange}>
                  <option value="">Sélectionner un produit</option>
                  {produits[deleteType].map((p, index) => (
                    <option key={index} value={p.nom}>
                      {p.nom}
                    </option>
                  ))}
                </select>
                {selectedProduit && <button onClick={handleDelete}>Supprimer</button>}
              </>
            )}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleProduits;
