import React, { useEffect, useState } from 'react';

function FacebookVitrine() {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [width, setWidth] = useState('340');
  const [height, setHeight] = useState('500');

  useEffect(() => {
    const checkFacebookSDK = () => {
      if (window.FB) {
        setSdkLoaded(true);
      } else {
        setTimeout(checkFacebookSDK, 1000);
      }
    };

    checkFacebookSDK();
  }, []);

  useEffect(() => {
    if (sdkLoaded) {
      window.FB.XFBML.parse();
    }
  }, [sdkLoaded]);

  useEffect(() => {
    const updateDimensions = () => {
      if (window.innerWidth >= 500) {
        setWidth('500');
        setHeight('500');
      } else if (window.innerWidth >= 400) {
        setWidth('400');
        setHeight('400');
      } else {
        setWidth('320');
        setHeight('320');
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div>
      {sdkLoaded ? (
        <div
          className="fb-page"
          data-href="https://www.facebook.com/p/La-houblonni%C3%A8re-de-Lezerzot-100057630991740/?locale=fr_FR"
          data-tabs="timeline"
          data-width={width}
          data-height={height}
          data-small-header="false"
          data-adapt-container-width="false"
          data-hide-cover="false"
          data-show-facepile="true"
        ></div>
      ) : (
        <div>Loading Facebook...</div>
      )}
    </div>
  );
}

export default FacebookVitrine;
