import React, { useState, useEffect, useRef } from 'react';
import './histoire.scss';
import Bandeau from '../../components/bandeau/bandeau';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import histoireData from '../../data/histoire.json';
import bandeauxData from '../../data/bandeaux.json';
import ModaleHistoire from '../../components/modaleHistoire/modaleHistoire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Histoire() {
  const [isModaleOpen, setIsModaleOpen] = useState(false);

  const isLocalEnv = window.location.hostname === 'localhost';

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const bandeauData = bandeauxData.find(b => b.id === 'histoire');
  
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [timelineEvents, setTimelineEvents] = useState(histoireData);
  const contentDateRef = useRef(null);
  const swipeRef = useRef(null);

  const formatMonth = (month) => {
    const months = [
      "JANVIER", "FEVRIER", "MARS", "AVRIL", "MAI", "JUIN",
      "JUILLET", "AOUT", "SEPTEMBRE", "OCTOBRE", "NOVEMBRE", "DECEMBRE"
    ];
    return months[parseInt(month, 10) - 1];
  };

  const formatDate = (date) => {
    const [month, year] = date.split('/');
    return `${formatMonth(month)} ${year}`;
  };

  const sortedTimelineEvents = timelineEvents.sort((a, b) => {
    const [monthA, yearA] = a.date.split('/');
    const [monthB, yearB] = b.date.split('/');
    return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
  });

  const totalEvents = sortedTimelineEvents.length;
  const spacingPercentage = 10;

  useEffect(() => {
    if (contentDateRef.current) {
      const contentDateElement = contentDateRef.current;
      contentDateElement.style.height = 'auto';
      const autoHeight = contentDateElement.scrollHeight + 'px';
      contentDateElement.style.height = '0px';
      contentDateElement.getBoundingClientRect();
      contentDateElement.style.height = autoHeight;
    }
  }, [selectedDateIndex]);

  useEffect(() => {
    const handleTouchStart = (e) => {
      swipeRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
      if (swipeRef.current !== null) {
        const touchEnd = e.changedTouches[0].clientX;
        const swipeDiff = swipeRef.current - touchEnd;

        if (swipeDiff > 50 && selectedDateIndex < totalEvents - 1) {
          setSelectedDateIndex(selectedDateIndex + 1);
        } else if (swipeDiff < -50 && selectedDateIndex > 0) {
          setSelectedDateIndex(selectedDateIndex - 1);
        }
        swipeRef.current = null;
      }
    };

    const container = document.querySelector('.timeline-container');
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchend', handleTouchEnd);

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, [selectedDateIndex, totalEvents]);

  const handleOpenModale = () => {
    setIsModaleOpen(true);
  };

  const handleCloseModale = () => {
    setIsModaleOpen(false);
  };

  return (
    <div className="histoire">
      {bandeauData && (
        <Bandeau
        id={bandeauData.id}
        title={bandeauData.title}
        text={bandeauData.text}
        imageUrl={bandeauData.imageUrl}
        imagePosition={bandeauData.imagePosition}
        cadreImage={bandeauData.cadreImage}
      />
      )}
      <section className="timeline-container">
        {isLocalEnv && (
          <button className="btn-modifier" onClick={handleOpenModale}>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
        <SectionTitle id="histoire"/>
        <div className="background-photo">
          <div className="content-date" ref={contentDateRef}>
            {sortedTimelineEvents[selectedDateIndex] && (
              <article className="content">
                <p className='bold'>{formatDate(sortedTimelineEvents[selectedDateIndex].date)}</p>
                <p style={{ whiteSpace: 'pre-wrap' }}>{sortedTimelineEvents[selectedDateIndex].description}</p>
                {sortedTimelineEvents[selectedDateIndex].links && sortedTimelineEvents[selectedDateIndex].links.length > 0 && (
                  <ul className="event-links">
                    {sortedTimelineEvents[selectedDateIndex].links.map((link, index) => (
                      <li key={index}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            )}
          </div>
          {sortedTimelineEvents.map((event, index) => (
            <img
              key={index}
              src={event.photo}
              alt={`Evènement: ${event.date}`}
              className={index === selectedDateIndex ? 'selected' : ''}
            />
          ))}
          <div className="frise__div">
            <div className="frise"></div>
            {sortedTimelineEvents.map((event, index) => (
              <button
                key={index}
                className={`date ${index === selectedDateIndex ? 'active' : ''}`}
                onClick={() => setSelectedDateIndex(index)}
                style={{
                  left: `${(index / (totalEvents - 1)) * (100 - 2 * spacingPercentage) + spacingPercentage}%`
                }}
              >
                <div className="month">{formatMonth(event.date.split('/')[0])}</div>
                <div className="year">{event.date.split('/')[1]}</div>
              </button>
            ))}
          </div>
        </div>
      </section>
      {isModaleOpen && (
        <ModaleHistoire 
          isOpen={isModaleOpen} 
          onClose={handleCloseModale} 
          events={timelineEvents} 
          setEvents={setTimelineEvents} 
        />
      )}
    </div>
  );
}

export default Histoire;
