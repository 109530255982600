import React, { useState, useEffect } from 'react';
import './contact.scss';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import contactInfo from '../../data/contactInfo.json';

function Contact() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', form);
  };

  const formatTelephoneForLink = (telephone) => {
    return telephone.replace(/[.\s]/g, '');
  };

  return (
    <div className="contact">
      <SectionTitle id="contact" />
      <div className="form-contactInfo">
        <div className="contact-info">
          <div className="info">
            <p className="titre">La Houblonnière de Lezerzot</p>
            <a href={`tel:${formatTelephoneForLink(contactInfo.telephone)}`} className="tel">{contactInfo.telephone}</a>
            <address>
              <p>
                <a 
                  href={contactInfo.googleMapsLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {contactInfo.adresse}
                </a>
              </p>
            </address>
            <a href={`mailto:${contactInfo.email}`} className="email">{contactInfo.email}</a>
          </div>
          <div>
            <img src={contactInfo.logoText} alt="Logo de La Houblonnière de Lezerzot" className="logo" />
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              value={form.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Sujet</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={form.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={form.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn-submit">Envoyer</button>
        </form>
      </div>
      <div className="info-card">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d674977.0051659036!2d-3.890884668901311!3d48.6360825163239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48118ba2ee7daa85%3A0xa76a4ee8e4600b5e!2sLa%20Houblonni%C3%A8re%20de%20Lezerzot!5e0!3m2!1sfr!2sfr!4v1719218671845!5m2!1sfr!2sfr"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps - La Houblonnière de Lezerzot"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
