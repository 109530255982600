import React, { useState, useEffect } from 'react';
import './bandeauAdmin.scss';
import contactInfo from '../../data/contactInfo.json';

function BandeauAdmin() {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    telephone: '',
    adresse: '',
    facebook: '',
    instagram: '',
    logoText: '',
    logo: ''
  });

  useEffect(() => {
    setFormData(contactInfo);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'telephone') {
      const formattedValue = formatTelephone(value);
      setFormData({
        ...formData,
        [name]: formattedValue
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setFormData({
      ...formData,
      [name]: file
    });
  };

  const handleSave = () => {
    const data = new FormData();
    data.append('email', formData.email);
    data.append('telephone', formData.telephone);
    data.append('adresse', formData.adresse);
    data.append('facebook', formData.facebook);
    data.append('instagram', formData.instagram);
    
    if (formData.logoText instanceof File) {
      data.append('logoText', formData.logoText);
    }
    
    if (formData.logo instanceof File) {
      data.append('logo', formData.logo);
    }

    fetch('http://localhost:5000/api/bandeauAdmin/update', {
      method: 'PUT',
      body: data
    })
    .then(response => response.json())
    .then(data => {
      console.log('Data saved:', data);
      setIsOpen(false);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // Function to format the telephone number
  const formatTelephone = (input) => {
    // Remove all non-digit characters
    const digits = input.replace(/\D/g, '').substring(0, 10); // Limit to 10 digits
    // Add a dot every two digits
    return digits.replace(/(\d{2})(?=\d)/g, '$1.');
  };

  return (
    <>
      <div className="bandeau-admin">
        <p>Vous êtes en mode admin local. <a href="#" onClick={() => setIsOpen(true)}>Accéder aux informations générales</a></p>
      </div>

      {isOpen && (
        <div className="modale">
          <div className="modale-content">
            <div className="modale-header">
              <button className="close-button" onClick={handleClose}>&times;</button>
            </div>
            <div className="modale-body">
              <div>
                email
                <input 
                  type="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleInputChange} 
                  placeholder="Email" 
                />
              </div>
              <div>
                téléphone
                <input 
                  type="tel" 
                  name="telephone" 
                  value={formData.telephone} 
                  onChange={handleInputChange} 
                  placeholder="Téléphone" 
                  maxLength={14} // Adjusted to account for dots
                />
              </div>
              <div>
                adresse
                <input 
                  type="text" 
                  name="adresse" 
                  value={formData.adresse} 
                  onChange={handleInputChange} 
                  placeholder="Adresse" 
                />
              </div>
              <div>
                facebook
                <input 
                  type="url" 
                  name="facebook" 
                  value={formData.facebook} 
                  onChange={handleInputChange} 
                  placeholder="URL Facebook" 
                />
              </div>
              <div>
                instagram
                <input 
                  type="url" 
                  name="instagram" 
                  value={formData.instagram} 
                  onChange={handleInputChange} 
                  placeholder="URL Instagram" 
                />
              </div>
              <div>
                <span>logo avec texte</span>
                <input 
                  type="file" 
                  name="logoText" 
                  onChange={handleFileChange} 
                  placeholder="Logo Text" 
                />
              </div>
              <div>
                <span>logo sans texte</span>
                <input 
                  type="file" 
                  name="logo" 
                  onChange={handleFileChange} 
                  placeholder="Logo" 
                />
              </div>
              <button onClick={handleSave}>Enregistrer</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BandeauAdmin;
