import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleAbout.scss';

function ModaleAbout({ isOpen, onClose, team, setTeam }) {
  const [mode, setMode] = useState('add');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState(null);
  const [position, setPosition] = useState(team.length + 1);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    resetForm();
  }, [mode]);

  const resetForm = () => {
    setName('');
    setDescription('');
    setPhoto('');
    setFile(null);
    setPosition(team.length + 1);
    setSelectedMember(null);
  };

  const handleAdd = async () => {
    if (!name || !description || !file) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('photo', file);
    formData.append('position', team.length + 1);

    try {
      const response = await fetch('http://localhost:5000/api/about/add', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();

      const updatedMember = {
        ...result.data[result.data.length - 1],
        photo: `${result.data[result.data.length - 1].photo}?t=${new Date().getTime()}`,
      };

      setTeam([...team, updatedMember]);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const handleEdit = async () => {
    if (!selectedMember) return;
    if (!name || !description || (!file && !photo)) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const formData = new FormData();
    formData.append('originalName', selectedMember.name);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('position', position);
    if (file) {
      formData.append('photo', file);
    }

    try {
      const response = await fetch('http://localhost:5000/api/about/update', {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setTeam(result.data);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error updating member:', error);
      alert('Erreur lors de la mise à jour du membre. Veuillez vérifier les détails et réessayer.');
    }
  };

  const handleDelete = async () => {
    if (!selectedMember) return;

    try {
      const response = await fetch('http://localhost:5000/api/about/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: selectedMember.name }),
      });
      const result = await response.json();
      setTeam(result.data);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const handleSelectChange = (e) => {
    const selectedName = e.target.value;
    const selected = team.find((member) => member.name === selectedName);
    setSelectedMember(selected);
    if (selected) {
      setName(selected.name);
      setDescription(selected.description);
      setPhoto(selected.photo || '');
      setPosition(selected.position);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPhoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  const renderPositionOptions = () => {
    const maxPosition = team.length;
    const options = [];
    for (let i = 1; i <= maxPosition; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'add' ? 'active' : ''} onClick={() => setMode('add')}>Ajouter</button>
            <button className={mode === 'edit' ? 'active' : ''} onClick={() => setMode('edit')}>Modifier</button>
            <button className={mode === 'delete' ? 'active' : ''} onClick={() => setMode('delete')}>Supprimer</button>
          </div>
          <div className="modale-body">
            {mode === 'edit' && (
              <select value={selectedMember ? selectedMember.name : ''} onChange={handleSelectChange} required>
                <option value="">Sélectionner un membre</option>
                {team.map((member, index) => (
                  <option key={index} value={member.name}>{member.name}</option>
                ))}
              </select>
            )}
            {(mode === 'add' || (mode === 'edit' && selectedMember)) && (
              <>
                <input type="text" placeholder="Nom" value={name} onChange={(e) => setName(e.target.value)} required />
                <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                {mode === 'edit' && selectedMember && (
                  <select value={position} onChange={(e) => setPosition(e.target.value)}>
                    {renderPositionOptions()}
                  </select>
                )}
                <input type="file" accept="image/*" onChange={handleFileChange} />
                <button onClick={mode === 'add' ? handleAdd : handleEdit}>
                  {mode === 'add' ? 'Ajouter' : 'Modifier'}
                </button>
              </>
            )}
            {mode === 'delete' && (
              <>
                <select value={selectedMember ? selectedMember.name : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner un membre</option>
                  {team.map((member, index) => (
                    <option key={index} value={member.name}>{member.name}</option>
                  ))}
                </select>
                {selectedMember && <button onClick={handleDelete}>Supprimer</button>}
              </>
            )}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleAbout;
