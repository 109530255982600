import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modaleValeurs.scss';

function ModaleValeurs({ isOpen, onClose, valeurs, setValeurs }) {
  const [mode, setMode] = useState('add');
  const [id, setId] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedValeur, setSelectedValeur] = useState(null);

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    resetForm();
  }, [mode]);

  const resetForm = () => {
    setId(null);
    setTitle('');
    setContent('');
    setSelectedValeur(null);
  };

  const handleAdd = () => {
    if (!title || !content) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const newValeur = {
      id: valeurs.length + 1,
      title,
      content
    };

    fetch('http://localhost:5000/api/valeur/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newValeur)
    })
    .then(res => res.json())
    .then(data => {
      setValeurs(data.data);
      resetForm();
      onClose();
    })
    .catch(error => console.error('Error adding valeur:', error));
  };

  const handleEdit = () => {
    if (!selectedValeur) return;
    if (!title || !content) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const updatedValeur = {
      id: selectedValeur.id,
      title,
      content
    };

    fetch('http://localhost:5000/api/valeur/update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedValeur)
    })
    .then(res => res.json())
    .then(data => {
      setValeurs(data.data);
      resetForm();
      onClose();
    })
    .catch(error => console.error('Error updating valeur:', error));
  };

  const handleDelete = () => {
    if (!selectedValeur) return;

    fetch('http://localhost:5000/api/valeur/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: selectedValeur.id })
    })
    .then(res => res.json())
    .then(data => {
      setValeurs(data.data);
      resetForm();
      onClose();
    })
    .catch(error => console.error('Error deleting valeur:', error));
  };

  const handleSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    const selected = valeurs.find(valeur => valeur.id === selectedId);
    setSelectedValeur(selected);
    if (selected) {
      setId(selected.id);
      setTitle(selected.title);
      setContent(selected.content);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modale')) {
      resetForm();
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="modale" onClick={handleOverlayClick}>
        <div className="modale-content">
          <div className="modale-header">
            <button className={mode === 'add' ? 'active' : ''} onClick={() => setMode('add')}>Ajouter</button>
            <button className={mode === 'edit' ? 'active' : ''} onClick={() => setMode('edit')}>Modifier</button>
            <button className={mode === 'delete' ? 'active' : ''} onClick={() => setMode('delete')}>Supprimer</button>
          </div>
          <div className="modale-body">
            {mode === 'add' && (
              <>
                <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required />
                <textarea placeholder="Contenu" value={content} onChange={(e) => setContent(e.target.value)} required />
                <button onClick={handleAdd}>Ajouter</button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <select value={selectedValeur ? selectedValeur.id : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner une valeur</option>
                  {valeurs.map((valeur) => (
                    <option key={valeur.id} value={valeur.id}>{valeur.title}</option>
                  ))}
                </select>
                {selectedValeur && (
                  <>
                    <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    <textarea placeholder="Contenu" value={content} onChange={(e) => setContent(e.target.value)} required />
                    <button onClick={handleEdit}>Modifier</button>
                  </>
                )}
              </>
            )}
            {mode === 'delete' && (
              <>
                <select value={selectedValeur ? selectedValeur.id : ''} onChange={handleSelectChange} required>
                  <option value="">Sélectionner une valeur</option>
                  {valeurs.map((valeur) => (
                    <option key={valeur.id} value={valeur.id}>{valeur.title}</option>
                  ))}
                </select>
                {selectedValeur && <button onClick={handleDelete}>Supprimer</button>}
              </>
            )}
          </div>
          <div className="modale-footer">
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ModaleValeurs;
