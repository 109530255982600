import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './produit.scss';
import Bandeau from '../../components/bandeau/bandeau';
import produitsData from '../../data/produits.json';
import bandeauxData from '../../data/bandeaux.json';
import SectionTitle from '../../components/sectionTitle/sectionTitle';
import ModaleProduits from '../../components/modaleProduits/modaleProduits';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Produit() {
  const [isLocalhost, setIsLocalhost] = useState(false);
  const [isModaleOpen, setIsModaleOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (window.location.hostname === 'localhost') {
      setIsLocalhost(true);
    }
  }, []);

  const bandeauData = bandeauxData.find(b => b.id === 'produits');

  const sortedProduits = {
    agneau: produitsData.agneau.sort((a, b) => a.position - b.position),
    produitsArtisanaux: produitsData.produitsArtisanaux.sort((a, b) => a.position - b.position),
  };

  const openModale = () => {
    setIsModaleOpen(true);
  };

  const closeModale = () => {
    setIsModaleOpen(false);
  };

  return (
    <div className="produit">
      <div className="produit-content">
        {bandeauData && (
          <Bandeau
            id={bandeauData.id}
            title={bandeauData.title}
            text={bandeauData.text}
            imageUrl={bandeauData.imageUrl}
            imagePosition={bandeauData.imagePosition}
            cadreImage={bandeauData.cadreImage}
          />
        )}
        <SectionTitle id="produit" />
        <section className="produit-tarifs">
        {isLocalhost && (
          <button className="btn-modifier" onClick={openModale}>
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
          <article className="produit-tarif-item">
            <h2>Agneau</h2>
            {sortedProduits.agneau.map((item, index) => (
              <div key={index} className='itemNomPrixPrecision'>
                <p><strong>{item.nom}</strong>{item.prix && ` : ${item.prix}`}</p>
                {item.precision && <p className='precision'>{item.precision}</p>}
              </div>
            ))}
            <Link to="/contact" className="btn-commander">Commander</Link>
          </article>
          <article className="produit-tarif-item">
            <h2>Produits artisanaux</h2>
            {sortedProduits.produitsArtisanaux.map((item, index) => (
              <div key={index} className='itemNomPrixPrecision'>
                <p><strong>{item.nom}</strong>{item.prix && ` : ${item.prix}`}</p>
                {item.precision && <p className='precision'>{item.precision}</p>}
              </div>
            ))}
            <Link to="/contact" className="btn-commander">Commander</Link>
          </article>
        </section>
        <div className='presenceMarche'>
          <span className='mainSpan'>Retrouvez-nous sur les marchés :</span>
          <div className='marche'>
            <strong>Marché paysan de Kermoroch</strong> tous les vendredis de 16h à 19h lieu dit Goasorgant (vente au détail d'agneau, pensez à réserver).
            <a href='https://www.facebook.com/p/March%C3%A9-Paysan-de-Kermoroch-100090260895592/?paipv=0&eav=AfZuklWlxrQbTJ8mvIETFVv4S7DtIKtJptwhfzAfTJHyPM3deOSQ_nvK8eZqHe46e7M&_rdr'>Voir la page Facebook</a>
          </div>
          <div className='marche'>
             <strong>Marché de producteurs Trézélan</strong> un mardi sur deux 16h 19h, 76 rue de Trézélan, Bégard.
             <a href='https://www.facebook.com/profile.php?id=61557992750984'>Voir la page Facebook</a>
          </div>
        </div>
      </div>
      {isModaleOpen && <ModaleProduits isOpen={isModaleOpen} onClose={closeModale} />}
    </div>
  );
}

export default Produit;
